import * as yup from 'yup';
var emailRegExp = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
var passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@¡!%*¿?&="#(\)/{}[\]`:;<>,~|^+'._-])([A-Za-z\d$@¡!%*¿?&="#(\)/{}[\]`:;<>,~|^+'._-]|[^ ]){8,20}$/;
var phone = /^[0-9]+$/;
export var schemaValidate = yup
    .object()
    .shape({
    indicative: yup.string().required('Please select one indicative'),
    acceptedTerms: yup.string().required('Please accept the terms and conditions'),
    names: yup
        .string()
        .max(30, 'Contact name must contain a maximum of 30 characters')
        .required('Please type your name'),
    surnames: yup
        .string()
        .max(30, 'Contact last name must contain a maximum of 30 characters')
        .required('Please type your last name'),
    telephone: yup
        .string()
        .matches(phone, 'The field cannot be empty and must contain numbers only')
        .max(10, 'Phone number must contain at least 10 characters')
        .min(10, 'Phone number must contain at least 10 characters')
        .required('Please type your telephone'),
    password: yup
        .string()
        .matches(passwordRegExp, 'The password must have a minimum of 8 characters, numbers, uppercase, lowercase and a special character.')
        .required('Please type your password'),
    passwordConfirm: yup
        .string()
        .matches(passwordRegExp, 'The password must have a minimum of 8 characters, numbers, uppercase, lowercase and a special character.')
        .required('Please confirm your password')
        .oneOf([yup.ref('password'), ''], 'Passwords must match'),
    email: yup
        .string()
        .matches(emailRegExp, 'The email is invalid, it must start with letters or numbers and contain @. Example: myemail@gmail.com')
        .required('Please type your email'),
    //.oneOf([yup.ref('email_verify'), null], 'Emails must match'),
    email_verify: yup
        .string()
        .matches(emailRegExp, 'The email is invalid, it must start with letters or numbers and contain @. Example: myemail@gmail.com')
        .required('Please type your email')
        .oneOf([yup.ref('email'), ''], 'Emails must match'),
    state: yup.string(),
    city: yup.string(),
})
    .required();
