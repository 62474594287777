import * as yup from 'yup';
var passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@¡!%*¿?&="#(\)/{}[\]`:;<>,~|^+'._-])([A-Za-z\d$@¡!%*¿?&="#(\)/{}[\]`:;<>,~|^+'._-]|[^ ]){8,20}$/;
var emailRegExp = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
export var schemaValidate = yup
    .object()
    .shape({
    email: yup
        .string()
        .matches(emailRegExp, 'The email is invalid, it must start with letters or numbers and contain @. Example: myemail@gmail.com')
        .required('Please type your email'),
    password: yup
        .string()
        .matches(passwordRegExp, 'The password must have a minimum of 8 characters, numbers, uppercase, lowercase and a special character.')
        .required('Please type your password'),
})
    .required();
