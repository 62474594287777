var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useForm, useFormState } from 'react-hook-form';
import { schemaValidate } from "./validations";
import { yupResolver } from '@hookform/resolvers/yup';
import DynamicComponentLoader from "../../shared/components/styleGuide";
import { styleguide } from "../../../config";
import { Container, ContentInputs } from "./style";
import { SocialMedia } from "../signin/style";
function Signup(_a) {
    var _b, _c, _d, _e, _f, _g;
    var media = _a.media, colors = _a.colors;
    var _h = useForm({
        mode: 'onChange',
        defaultValues: {},
        resolver: yupResolver(schemaValidate),
    }), control = _h.control, setValue = _h.setValue, register = _h.register, watch = _h.watch;
    var _j = useFormState({ control: control }), errors = _j.errors, isValid = _j.isValid;
    var handleClick = function () {
        console.log("Button clicked!");
    };
    return (_jsxs(Container, __assign({ media: media }, { children: [_jsx("h2", { children: "Register" }), _jsxs(ContentInputs, __assign({ media: media }, { children: [_jsx(DynamicComponentLoader, { remote: styleguide, module: "./InputText", props: {
                            type: "text",
                            label: "Name*",
                            placeholder: "Enter your name",
                            message: (_b = errors === null || errors === void 0 ? void 0 : errors.name) === null || _b === void 0 ? void 0 : _b.message,
                            hasErrors: !!(errors === null || errors === void 0 ? void 0 : errors.name),
                            onPaste: true,
                        } }), _jsx(DynamicComponentLoader, { remote: styleguide, module: "./InputText", props: {
                            type: "text",
                            label: "Last name*",
                            placeholder: "Enter your last name",
                            message: (_c = errors === null || errors === void 0 ? void 0 : errors.lastName) === null || _c === void 0 ? void 0 : _c.message,
                            hasErrors: !!(errors === null || errors === void 0 ? void 0 : errors.lastName),
                            onPaste: true,
                        } })] })), _jsxs(ContentInputs, __assign({ media: media }, { children: [_jsx(DynamicComponentLoader, { remote: styleguide, module: "./InputText", props: {
                            type: "text",
                            label: "Email*",
                            placeholder: "Enter your email",
                            message: (_d = errors === null || errors === void 0 ? void 0 : errors.email) === null || _d === void 0 ? void 0 : _d.message,
                            hasErrors: !!(errors === null || errors === void 0 ? void 0 : errors.email),
                            onPaste: true,
                        } }), _jsx(DynamicComponentLoader, { remote: styleguide, module: "./InputText", props: {
                            type: "text",
                            label: "Phone*",
                            placeholder: "Enter your phone",
                            message: (_e = errors === null || errors === void 0 ? void 0 : errors.phone) === null || _e === void 0 ? void 0 : _e.message,
                            hasErrors: !!(errors === null || errors === void 0 ? void 0 : errors.phone),
                            onPaste: true,
                        } })] })), _jsxs(ContentInputs, __assign({ media: media }, { children: [_jsx(DynamicComponentLoader, { remote: styleguide, module: "./InputText", props: {
                            type: "password",
                            showPassword: true,
                            label: "Password*",
                            placeholder: "Enter password",
                            message: (_f = errors === null || errors === void 0 ? void 0 : errors.password) === null || _f === void 0 ? void 0 : _f.message,
                            hasErrors: !!(errors === null || errors === void 0 ? void 0 : errors.password),
                        } }), _jsx(DynamicComponentLoader, { remote: styleguide, module: "./InputText", props: {
                            type: "password",
                            showPassword: true,
                            label: "Confirm Password*",
                            placeholder: "Enter confirm password",
                            message: (_g = errors === null || errors === void 0 ? void 0 : errors.confirmPassword) === null || _g === void 0 ? void 0 : _g.message,
                            hasErrors: !!(errors === null || errors === void 0 ? void 0 : errors.confirmPassword),
                        } })] })), _jsx(DynamicComponentLoader, { remote: styleguide, module: "./Button", props: {
                    typeButton: "primary",
                    type: "button",
                    textButton: "Register",
                    minWidth: "150px",
                    disabled: false,
                    onClick: handleClick,
                } }), _jsx("p", { children: "or login with social platforms" }), _jsxs(SocialMedia, __assign({ background: colors.black20, shadow: colors.shadow }, { children: [_jsx("div", { children: _jsx(DynamicComponentLoader, { remote: "styleGuideReact", module: "./Icon", props: {
                                icon: "google",
                                size: "24px",
                                color: colors.NeutralBackgroundWhite,
                                onClick: handleClick,
                            } }) }), _jsx("div", { children: _jsx(DynamicComponentLoader, { remote: "styleGuideReact", module: "./Icon", props: {
                                icon: "facebook",
                                size: "24px",
                                color: colors.NeutralBackgroundWhite,
                                onClick: handleClick,
                            } }) }), _jsx("div", { children: _jsx(DynamicComponentLoader, { remote: "styleGuideReact", module: "./Icon", props: {
                                icon: "instagram",
                                size: "24px",
                                color: colors.NeutralBackgroundWhite,
                                onClick: handleClick,
                            } }) }), _jsx("div", { children: _jsx(DynamicComponentLoader, { remote: "styleGuideReact", module: "./Icon", props: {
                                icon: "github",
                                size: "24px",
                                color: colors.NeutralBackgroundWhite,
                                onClick: handleClick,
                            } }) })] }))] })));
}
export default Signup;
