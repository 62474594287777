var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import Signin from "./Components/signin";
import Signup from "./Components/signup";
import { Container, LoginForm, RegisterForm, Section, ToggleBox, TogglePanel } from "./style";
import DynamicComponentLoader, { loadRemoteEntry, loadStylesRemoteModule } from "./shared/components/styleGuide";
import { styleguide_url } from "../config";
export default function Root(props) {
    var _this = this;
    var _a = useState(true), isLoginVisible = _a[0], setIsLoginVisible = _a[1];
    var _b = useState(null), colors = _b[0], setColors = _b[1];
    var _c = useState(null), media = _c[0], setMedia = _c[1];
    var showRegister = function () { return setIsLoginVisible(false); };
    var showLogin = function () { return setIsLoginVisible(true); };
    var handleClick = function () {
        console.log("Button clicked!");
    };
    useEffect(function () {
        loadRemoteEntry(styleguide_url).then(function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, loadStylesRemoteModule('styleGuideReact', './fonts')];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
        loadRemoteEntry(styleguide_url).then(function () { return __awaiter(_this, void 0, void 0, function () {
            var mediaModule;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, loadStylesRemoteModule('styleGuideReact', './media')];
                    case 1:
                        mediaModule = _a.sent();
                        setMedia(mediaModule.default);
                        return [2 /*return*/];
                }
            });
        }); });
        loadRemoteEntry(styleguide_url).then(function () { return __awaiter(_this, void 0, void 0, function () {
            var colorsModule;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, loadStylesRemoteModule('styleGuideReact', './colors')];
                    case 1:
                        colorsModule = _a.sent();
                        setColors(colorsModule.default);
                        return [2 /*return*/];
                }
            });
        }); });
    }, []);
    return (_jsx(_Fragment, { children: media && colors &&
            _jsx(Section, __assign({ color: colors === null || colors === void 0 ? void 0 : colors.NeutralBackgroundWhite, media: media, gradient: "90deg, ".concat(colors.black80, ", ").concat(colors.primary10) }, { children: _jsxs(Container, __assign({ className: !isLoginVisible ? "active" : "desactive", media: media }, { children: [_jsx(LoginForm, __assign({ id: "loginForm", className: "loginForm", media: media }, { children: _jsx(Signin, { media: media, colors: colors }) })), _jsx(RegisterForm, __assign({ id: "registerForm", className: "registerForm", media: media }, { children: _jsx(Signup, { media: media, colors: colors }) })), _jsxs(ToggleBox, __assign({ className: 'content-toggle', media: media, background: colors.primary10 }, { children: [_jsxs(TogglePanel, __assign({ className: 'left', media: media }, { children: [_jsx("h2", { children: "Hello, Welcome!" }), _jsx("p", { children: "Don't have an account?" }), _jsx(DynamicComponentLoader, { remote: "styleGuideReact", module: "./Button", props: {
                                                typeButton: "secondary",
                                                type: "button",
                                                textButton: "Register",
                                                minWidth: "150px",
                                                disabled: false,
                                                color: colors.NeutralBackgroundWhite,
                                                backgroundHover: colors.NeutralBackgroundWhite,
                                                onClick: showRegister,
                                            } })] })), _jsxs(TogglePanel, __assign({ className: 'right', media: media }, { children: [_jsx("h2", { children: "Welcome Back!" }), _jsx("p", { children: "Already have an account?" }), _jsx(DynamicComponentLoader, { remote: "styleGuideReact", module: "./Button", props: {
                                                typeButton: "secondary",
                                                type: "button",
                                                textButton: "Login",
                                                minWidth: "150px",
                                                disabled: false,
                                                color: colors.NeutralBackgroundWhite,
                                                backgroundHover: colors.NeutralBackgroundWhite,
                                                onClick: showLogin,
                                            } })] }))] }))] })) })) }));
}
