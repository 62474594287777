var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useForm, useFormState } from 'react-hook-form';
import { schemaValidate } from "./validations";
import { yupResolver } from '@hookform/resolvers/yup';
import DynamicComponentLoader from "../../shared/components/styleGuide";
import { Container, SocialMedia } from "./style";
function Signin(_a) {
    var _b, _c;
    var media = _a.media, colors = _a.colors;
    var _d = useForm({
        mode: 'onChange',
        defaultValues: {},
        resolver: yupResolver(schemaValidate),
    }), control = _d.control, setValue = _d.setValue, register = _d.register, watch = _d.watch;
    var _e = useFormState({ control: control }), errors = _e.errors, isValid = _e.isValid;
    var handleClick = function () {
        console.log("Button clicked!");
    };
    return (_jsxs(Container, __assign({ media: media }, { children: [_jsx("h2", { children: "Login" }), _jsx(DynamicComponentLoader, { remote: "styleGuideReact", module: "./InputText", props: {
                    type: "text",
                    label: "Email*",
                    placeholder: "Enter your email",
                    message: (_b = errors === null || errors === void 0 ? void 0 : errors.email) === null || _b === void 0 ? void 0 : _b.message,
                    hasErrors: !!(errors === null || errors === void 0 ? void 0 : errors.email),
                    onPaste: true,
                } }), _jsx(DynamicComponentLoader, { remote: "styleGuideReact", module: "./InputText", props: {
                    type: "password",
                    showPassword: true,
                    label: "Password*",
                    placeholder: "Enter password",
                    message: (_c = errors === null || errors === void 0 ? void 0 : errors.password) === null || _c === void 0 ? void 0 : _c.message,
                    hasErrors: !!(errors === null || errors === void 0 ? void 0 : errors.password),
                } }), _jsx("p", { children: "Forgot password?" }), _jsx(DynamicComponentLoader, { remote: "styleGuideReact", module: "./Button", props: {
                    typeButton: "primary",
                    type: "button",
                    textButton: "Login",
                    minWidth: "150px",
                    disabled: false,
                    onClick: handleClick,
                } }), _jsx("p", { children: "or login with social platforms" }), _jsxs(SocialMedia, __assign({ background: colors.black20, shadow: colors.shadow }, { children: [_jsx("div", { children: _jsx(DynamicComponentLoader, { remote: "styleGuideReact", module: "./Icon", props: {
                                icon: "google",
                                size: "24px",
                                color: colors.NeutralBackgroundWhite,
                                onClick: handleClick,
                            } }) }), _jsx("div", { children: _jsx(DynamicComponentLoader, { remote: "styleGuideReact", module: "./Icon", props: {
                                icon: "facebook",
                                size: "24px",
                                color: colors.NeutralBackgroundWhite,
                                onClick: handleClick,
                            } }) }), _jsx("div", { children: _jsx(DynamicComponentLoader, { remote: "styleGuideReact", module: "./Icon", props: {
                                icon: "instagram",
                                size: "24px",
                                color: colors.NeutralBackgroundWhite,
                                onClick: handleClick,
                            } }) }), _jsx("div", { children: _jsx(DynamicComponentLoader, { remote: "styleGuideReact", module: "./Icon", props: {
                                icon: "github",
                                size: "24px",
                                color: colors.NeutralBackgroundWhite,
                                onClick: handleClick,
                            } }) })] }))] })));
}
export default Signin;
